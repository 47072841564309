import $ from 'jquery';

$(window).scroll(function(){
    if ($(window).scrollTop() >= 50) {
        $('#Navbar').addClass('animate-info1');
        $('#Navbar').css("z-index" , "999999999999999999999");
    }
    else {
        $('#Navbar').removeClass('animate-info1');
    }
});

$(function () {
    $(".ddl-select").each(function () {
      $(this).hide();
      var $select = $(this);
      var _id = $(this).attr("id");
      var wrapper = document.createElement("div");
      wrapper.setAttribute("class", "ddl ddl_" + _id);
  
      var input = document.createElement("input");
      input.setAttribute("type", "text");
      input.setAttribute("class", "ddl-input");
      input.setAttribute("id", "ddl_" + _id);
      input.setAttribute("readonly", "readonly");
      input.setAttribute(
        "placeholder",
        $(this)[0].options[$(this)[0].selectedIndex].innerText
      );
  
      $(this).before(wrapper);
      var $ddl = $(".ddl_" + _id);
      $ddl.append(input);
      $ddl.append("<div class='ddl-options ddl-options-" + _id + "'></div>");
      var $ddl_input = $("#ddl_" + _id);
      var $ops_list = $(".ddl-options-" + _id);
      var $ops = $(this)[0].options;
      for (var i = 0; i < $ops.length; i++) {
        $ops_list.append(
          "<div data-value='" +
            $ops[i].value +
            "'>" +
            $ops[i].innerText +
            "</div>"
        );
      }
  
      $ddl_input.click(function () {
        $ddl.toggleClass("active");
      });
      $ddl_input.blur(function () {
        $ddl.removeClass("active");
      });
      $ops_list.find("div").click(function () {
        $select.val($(this).data("value")).trigger("change");
        $ddl_input.val($(this).text());
        $ddl.removeClass("active");
      });
    });
  });
  


$(function () {
    $(".ddl-select1").each(function () {
      $(this).hide();
      var $select1 = $(this);
      var _id = $(this).attr("id");
      var wrapper = document.createElement("div");
      wrapper.setAttribute("class", "ddl1 ddl1_" + _id);
  
      var input = document.createElement("input");
      input.setAttribute("type", "text");
      input.setAttribute("class", "ddl-input1");
      input.setAttribute("id", "ddl1_" + _id);
      input.setAttribute("readonly", "readonly");
      input.setAttribute(
        "placeholder",
        $(this)[0].options[$(this)[0].selectedIndex].innerText
      );
  
      $(this).before(wrapper);
      var $ddl1 = $(".ddl1_" + _id);
      $ddl1.append(input);
      $ddl1.append("<div class='ddl1-options ddl1-options-" + _id + "'></div>");
      var $ddl_input1 = $("#ddl1_" + _id);
      var $ops_list1 = $(".ddl1-options-" + _id);
      var $ops1 = $(this)[0].options;
      for (var i = 0; i < $ops1.length; i++) {
        $ops_list1.append(
          "<div data-value='" +
            $ops1[i].value +
            "'>" +
            $ops1[i].innerText +
            "</div>"
        );
      }
  
      $ddl_input1.click(function () {
        $ddl1.toggleClass("active");
      });
      $ddl_input1.blur(function () {
        $ddl1.removeClass("active");
      });
      $ops_list1.find("div").click(function () {
        $select1.val($(this).data("value")).trigger("change");
        $ddl_input1.val($(this).text());
        $ddl1.removeClass("active");
      });
    });
  });
  