import Slider from "react-slick"
import TitleSmall from "../PropsComponents/TitleSmall";
import I1 from "../../assets/img/1 (2).jpg";
import I2 from "../../assets/img/2 (1).jpg";
import I3 from "../../assets/img/3 (1).jpg";
import I4 from "../../assets/img/4 (1).jpg";
export default function HomeNews() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
              breakpoint: 860,
              settings: {
                slidesToShow: 1,
              }
            },
        ]
    };
    const sliderData = [
        { image: I1, title: 'Top Ten Creative Tattoo Styles', date: 'December, 29' },
        { image: I2, title: 'Best Tattoo Back Ideas', date: 'December, 27' },
        { image: I3, title: 'Best Wrap Around Tattoo Ideas', date: 'December, 25' },
        { image: I4, title: 'Top Seven Piercing Styles', date: 'December, 23' },
    ]
    return (
        <>
            <div id="Blogs" className="bg-[#101010] py-20">
                <div className="max-w-screen-xl mx-auto max-xl:px-6">
                    <div className={'text-center'}>
                        <TitleSmall SmallTitle={'BLOG ARTICLES'} Title={'LATEST NEWS'} TitleBorder={''} />
                    </div>
                    <div className="mt-12 TeamSlider sm:pb-12">
                        <Slider {...settings}>
                            {
                                sliderData && sliderData.map((item, index) => {
                                    return <div className="pb-16 cursor-pointer">
                                        <div className="relative px-4 over">
                                            <div className="overflow-hidden">
                                                <img src={item.image} className="transition-all duration-1000 hover:scale-110" />
                                            </div>
                                            <div className="absolute bg-black px-4 flex flex-col justify-center w-[80%] left-[10%] h-24 -bottom-12">
                                                <div className="text-[12px] sm:text-[20px] xl:text-[24px] text-white uppercase  sm:tracking-[3px] font-Oswald font-normal">{item.title}</div>
                                                <div className="text-[13px] mt-2"><span className="text-mainText text-[13px] pr-4">Blog</span> {item.date}</div>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    )
}
