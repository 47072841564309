
import I1 from "../../assets/img/price.jpg";
import TitleSmall from "../PropsComponents/TitleSmall";

import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';


export default function HomePrice() {
    const priceData = [
        { name: "Tattooing", price: "$60" },
        { name: "Tatoo Cover Up", price: "$45" },
        { name: "Piercing", price: "$30" },
        { name: "Laser Removal", price: "$75" },
    ]
    const images = [
        // {
        //     src:
        //         'https://duruthemes.com/demo/html/artefact/multipage-dark-image/img/gallery/4.jpg',
        //     www: 'w-1/2'
        // },
        // {
        //     src:
        //         'https://duruthemes.com/demo/html/artefact/multipage-dark-image/img/gallery/10.jpg',
        //     www: 'w-1/2'
        // },
        {
            src:
                'https://duruthemes.com/demo/html/artefact/multipage-dark-image/img/gallery/1.jpg',
            alt: 'w-1/3'
        },
        {
            src:
                'https://duruthemes.com/demo/html/artefact/multipage-dark-image/img/gallery/2.jpg',
            alt: 'w-1/3'
        },
        {
            src:
                'https://duruthemes.com/demo/html/artefact/multipage-dark-image/img/gallery/5.jpg',
            alt: 'w-1/3'
        }
    ];
    return (
        <>
            <div id="Portfolio" className="w-full bg-[#000000] py-24">
                <div className="max-w-screen-xl mx-auto py-12 text-center">
                    <TitleSmall SmallTitle={'Porrtfolio'} Title={'Image'} TitleBorder ="Gallery" />
                </div>
                <div className="max-w-screen-xl mx-auto">
                    <LightGallery
                        speed={500}
                        plugins={[lgThumbnail, lgZoom]}
                        className=''
                    >
                        {/* <div className="flex"> */}
                        {
                            images && images.map((item, index) => {
                                return <a href={item.src} key={index} className="aaa">
                                    <img alt={item.alt} src={item.src} />
                                </a>
                            })
                        }
                        {/* </div> */}
                    </LightGallery>
                </div>
            </div>
        </>
    )
}