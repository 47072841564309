import React from 'react';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';
import I1 from "../../assets/img/1 (3).jpg";
import I2 from "../../assets/img/2 (2).jpg";
import QImg from "../../assets/img/quot.png";
import {BsPlay} from 'react-icons/bs';
export default function HomeOpinion() {
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false
    };
    function closeModal() {
        setIsOpen(false);
    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };
    const SliderData = [
        { desc: "Tattoo viverra tristique duis vitae dias the nesueten niva aestan ateuene artines duruna setlie suscena fermen uisque sed tellus man lorem nullaman the rana tortor in the felis fermen.", image: I1, name: "Jason Brown", proffesion: "Customer Review" },
        { desc: "Tattoo viverra tristique duis vitae dias the nesueten niva aestan ateuene artines duruna setlie suscena fermen uisque sed tellus man lorem nullaman the rana tortor in the felis fermen.", image: I2, name: "Emily White", proffesion: "Customer Review" },
    ]
    return (
        <>
            <div className="w-full  relative after:absolute pb-12 after:-z-[1] after:top-0 after:left-0 after:w-full after:h-full after:bg-black/50 bg-contact-bg bg-center bg-cover bg-fixed before:absolute before:bottom-0 before:left-0 before:bg-black before:w-full  before:h-24">
                <div className="relative z-0 text-white  max-w-screen-xl mx-auto flex max-sm:flex-col space-y-6 pt-32 max-xl:px-6">
                    <div className="w-full sm:w-1/2 xl:w-4/6">
                        <button onClick={openModal} className='w-[90px] h-[90px] rounded-full border flex justify-center items-center transition-all duration-300 hover:bg-mainText'>
                            <BsPlay className='text-[30px] text-white' />
                        </button>
                        <Modal
                            isOpen={modalIsOpen}
                            // onAfterOpen={afterOpenModal}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                            
                        >
                            <button onClick={closeModal}>X</button>

                            <ReactPlayer controls  url='https://www.youtube.com/watch?v=UCRNVgJJ97w' />
                        </Modal>
                    </div>

                    <div className="w-full sm:w-1/2 xl:w-2/6 bg-[#101010] px-4 py-12">
                        <Slider {...settings}>
                            {
                                SliderData && SliderData.map((item, index) => {
                                    return <div className=''>
                                        <div className='px-4 border-l-[1px] border-mainText py-2'>
                                            {item.desc}
                                        </div>
                                        <div className='flex px-8 justify-between mt-4'>
                                            <div className='flex'>
                                                <div>
                                                    <img src={item.image} className='w-[52px] h-[52px] rounded-full' />
                                                </div>
                                                <div className='pl-4'>
                                                    <p className='font-Barlow text-white text-[15px] font-normal'>{item.name}</p>
                                                    <p className='text-[13px] text-[#999]'>{item.proffesion}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <img src={QImg} className='w-[70px] h-[49.5px]' />
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    )
}