import React from "react";
import { Link } from "react-scroll";

export default function TeamSlider(props) {
    return (
        <>
            <div className="w-full relative">
                <img src={props.image} className="lg:max-w-[66%] left-0" />
                <div className="lg:absolute px-[20px] lg:px-[60px] py-[15px] lg:py-[45px]  bg-[#101010] lg:w-[60%] lg:h-[60%] top-[20%]  z-10 right-0">
                    <p className="font-Oswald text-white text-[27px] font-normal uppercase tracking-[3px]">{props.name}</p>
                    <p className="text-mainText font-Oswald text-[13px] font-normal tracking-[1px]">{props.proffesion}</p>
                    <p className="mt-4">{props.description}</p>
                    <div className="mt-6 flex">
                        <Link className="relative z-0 bg-mainText cursor-pointer overflow-hidden after:transition-all after:duration-300 hover:text-black after:z-0 px-[24px] py-[12px] text-white text-[15px] font-medium after:absolute after:w-[110%] after:h-[130%] after:bg-white after:top-0 after:left-[-5%] after:rotate-[-5deg] after:translate-y-[-120%] hover:after:translate-y-[-12%]"><span className="relative z-10">View Details</span></Link>
                    </div>
                </div>

            </div>
        </>
    )
}