import { Link } from "react-scroll";

export default function HomeFirst() {
    return(
        <>
            <div className="w-full h-[100vh] bg-first-bg bg-cover bg-fixed bg-center flex justify-center items-center" id="Home">
                <div className="max-w-2xl">
                    <p className="text-mainText text-[13px] uppercase font-normal font-Oswald tracking-[5px] text-center">WELCOME TO ARTEFACT TATTOO</p>
                    <p className="text-white text-[30px] sm:text-[40px] md:text-[70px] font-Oswald uppercase tracking-[3px] font-medium text-center">BE PROUD OF YOUR</p>
                    <p className="text-transparent text-[30px] sm:text-[40px] md:text-[70px] font-Oswald uppercase tracking-[3px] font-medium leading-none text-center Text-Strock">New tattoo</p>
                    <div className="mt-12 flex justify-center">
                        <Link activeClass="Active" spy={true} smooth={true} offset={0} duration={500} to="Contact" className="relative z-0 bg-mainText cursor-pointer overflow-hidden after:transition-all after:duration-300 hover:text-black after:z-0 px-[24px] py-[12px] text-white text-[15px] font-medium after:absolute after:w-[110%] after:h-[130%] after:bg-white after:top-0 after:left-[-5%] after:rotate-[-5deg] after:translate-y-[-120%] hover:after:translate-y-[-12%]"><span className="relative z-10">Book Your Tattoo</span></Link>
                    </div>
                </div>    
            </div>
        </>
    )
}