import { TfiInstagram, TfiYoutube } from "react-icons/tfi";
import { SlSocialTwitter } from "react-icons/sl";
import { FaFacebookF, FaPinterestP } from "react-icons/fa";
import { BsPinterest } from "react-icons/bs";
import { Link } from "react-scroll";
export default function Footer() {
    return (
        <>
            <div className="w-full bg-[#101010] py-20">
                <div className="max-w-screen-xl mx-auto flex max-md:flex-wrap max-xl:px-6">
                    <div className="w-full sm:w-1/2 md:w-1/3">
                        <p className="text-[27px] text-white uppercase tracking-[3px] font-Oswald">Contact</p>
                        <div className="mt-6">
                            <p>00665 Broadway NY, New York 10001</p>
                            <p>United States of America</p>
                            <p className="mt-6 text-white text-[24px] font-Oswald">855 100 4444</p>
                            <p className="mt-4"><a className="border-b-[1px] border-mainText transition-all duration-300 hover:text-white cursor-pointer">info@tattoo.com</a></p>
                            <ul className="flex space-x-3 mt-8 text-white">
                                <li>
                                    <a href=""><TfiInstagram /></a>
                                </li>
                                <li>
                                    <a href=""><SlSocialTwitter /></a>
                                </li>
                                <li>
                                    <a href=""><TfiYoutube /></a>
                                </li>
                                <li>
                                    <a href=""><FaFacebookF /></a>
                                </li>
                                <li>
                                    <a href=""><BsPinterest /></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="w-full sm:w-1/2 md:w-1/3">
                        <p className="text-[27px] text-white uppercase tracking-[3px] font-Oswald">WORK TIME</p>
                        <ul className="mt-6 flex flex-col space-y-3">
                            <li className="flex  items-center">
                                <div className="w-1/5">Monday</div>
                                <div className="w-2/5  border-b-[1px]  border-mainText border-dotted"></div>
                                <div className="w-2/5 pl-4">10:00 - 20:00</div>
                            </li>
                            <li className="flex justify-between items-center">
                                <div className="w-1/5">Tuesday</div>
                                <div className="w-2/5 border-b-[1px]  border-mainText border-dotted"></div>
                                <div className="w-2/5 pl-4">10:00 - 20:00</div>
                            </li>
                            <li className="flex justify-between items-center">
                                <div className="w-1/5 ">Thursday	</div>
                                <div className="w-2/5 border-b-[1px]  border-mainText border-dotted"></div>
                                <div className="w-2/5 pl-4">10:00 - 20:00</div>
                            </li>
                            <li className="flex justify-between items-center">
                                <div className="w-1/5 ">Friday	</div>
                                <div className="w-2/5 border-b-[1px]  border-mainText border-dotted"></div>
                                <div className="w-2/5 pl-4">10:00 - 20:00</div>
                            </li>
                            <li className="flex justify-between items-center">
                                <div className="w-1/5 ">Saturday	</div>
                                <div className="w-2/5 border-b-[1px]  border-mainText border-dotted"></div>
                                <div className="w-2/5 pl-4">10:00 - 20:00</div>
                            </li>
                            <li className="flex justify-between items-center">
                                <div className="w-1/5 ">Weekend	</div>
                                <div className="w-2/5 border-b-[1px]  border-mainText border-dotted"></div>
                                <div className="w-2/5 pl-4">Closed</div>
                            </li>

                        </ul>
                    </div>
                    <div className="w-full md:w-1/3 max-md:mt-6 flex flex-col">
                        <p className="text-[27px] text-white uppercase tracking-[3px] font-Oswald">Pages</p>
                        <ul className='flex flex-col mt-6  space-y-3'>
                            <li className='Header__Links'><Link to={'Home'} activeClass="Active" spy={true} smooth={true} offset={0} duration={500}>home </Link> </li>
                            <li className='Header__Links'><Link to={'About'} activeClass="Active" spy={true} smooth={true} offset={0} duration={500}>about </Link> </li>
                            <li className='Header__Links'><Link to={'Services'} activeClass="Active" spy={true} smooth={true} offset={0} duration={500}>services </Link> </li>
                            <li className='Header__Links'><Link to={'Portfolio'} activeClass="Active" spy={true} smooth={true} offset={0} duration={500}>Portfolio </Link> </li>
                            <li className='Header__Links'><Link to={'Team'} activeClass="Active" spy={true} smooth={true} offset={0} duration={500}>team </Link> </li>
                            <li className='Header__Links'><Link to={'Blogs'} activeClass="Active" spy={true} smooth={true} offset={0} duration={500}>blog </Link> </li>
                            <li className='Header__Links'><Link to={'Contact'} activeClass="Active" spy={true} smooth={true} offset={0} duration={500}>contact </Link> </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}