import { Route, Routes } from "react-router-dom";
import Home from "./Pages/home";
import Header from "./Pages/Layouts/header";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "./Pages/Layouts/Footer";
function App() {
  return (
    <>
    <Header />
        <Routes>
          <Route path="" element={<Home />} />
        </Routes>
    <Footer />    
    </>
  );
}

export default App;
