import HomeContact from "./Components/HomeContact";
import HomeFirst from "./Components/HomeFirst";
import HomeHow from "./Components/HomeHow";
import HomeNews from "./Components/HomeNews";
import HomeOpinion from "./Components/HomeOpinion";
import HomePrice from "./Components/HomePrice";
import HomeSecond from "./Components/HomeSecond";
import HomeService from "./Components/HomeService";
import HomeTeam from "./Components/Hometeam";

export default function Home() {
    return(
        <>
            <HomeFirst />
            <HomeSecond />
            <HomeHow />
            <HomeContact />
            <HomeTeam />
            <HomeService />
            <HomePrice />
            <HomeNews />
            <HomeOpinion />
        </>
    )
}