import React from "react";
import TitleSmall from "../PropsComponents/TitleSmall";
import I1 from "../../assets/img/1 (1).jpg";
import I2 from "../../assets/img/4.jpg";
import I3 from "../../assets/img/3.jpg";
import ServiceSmall from "../PropsComponents/ServiceSmall";
import ServiceSmall2 from "../PropsComponents/ServiceSmall2";
export default function HomeService() {
    const serviceData= [
        {image:I1 ,ID:'01' , title:"TATTOOING" , desc:"Lorem arena nuam enim mi obortis esen the uctus accumsan golden alisuame amet auctor orci done vitae vehicula risus duise nun sapien accumsan imauris ullamcorper rutrum asuam."},
        {image:I2 ,ID:'02' , title:"PIERCING" , desc:"Lorem arena nuam enim mi obortis esen the uctus accumsan golden alisuame amet auctor orci done vitae vehicula risus duise nun sapien accumsan imauris ullamcorper rutrum asuam."},
        {image:I3 ,ID:'03' , title:"LASER REMOVAL" , desc:"Lorem arena nuam enim mi obortis esen the uctus accumsan golden alisuame amet auctor orci done vitae vehicula risus duise nun sapien accumsan imauris ullamcorper rutrum asuam."},
    ];
    return (
        <>
            <div id="Services" className="w-full bg-[#101010] py-36 relative  before:absolute before:top-[-40px] before:left-[50%] before:w-[1px] before:h-[80px] before:bg-mainText">
                <div className="max-w-screen-lg mx-auto max-xl:px-6">
                    <div className={''}>
                        <TitleSmall SmallTitle={'WHAT WE DO'} Title={'OUR SERVICES'} TitleBorder={''} />
                    </div>
                    <div className="mt-12">
                          {
                            serviceData && serviceData.map((item , index) => {
                                if(index % 2 ==0 ){
                                      return <ServiceSmall image={item.image} ID={item.ID} title={item.title} desc={item.desc} />  
                                }else{
                                    return <ServiceSmall2 image={item.image} ID={item.ID} title={item.title} desc={item.desc} className={'flex-row-reverse'} />  
                                }
                            })
                          }  
                    </div>
                </div>
            </div>
        </>
    )
}

