import React from "react";
import Logo from "../../assets/img/logo-light.png";
import { Link } from "react-scroll";
import { FaBars } from "react-icons/fa";
import "../../assets/js/script";
export default function Header() {
    const [openTop, setOpenTop] = React.useState(false);

    return (
        <>
            <div className="w-full fixed top-0" id="Navbar">
                <div className="max-w-screen-lg mx-auto max-xl:px-6 py-6 flex justify-between items-center">
                    <div>
                        <img src={Logo} className="w-[140px] h-[54px]" />
                    </div>
                    <div>
                        <ul className='flex space-x-6 max-md:hidden'>
                            <li className='Header__Links'><Link to={'Home'} activeClass="Active" spy={true} smooth={true} offset={0} duration={500}>home </Link> </li>
                            <li className='Header__Links'><Link to={'About'} activeClass="Active" spy={true} smooth={true} offset={0} duration={500}>about </Link> </li>
                            <li className='Header__Links'><Link to={'Services'} activeClass="Active" spy={true} smooth={true} offset={0} duration={500}>services </Link> </li>
                            <li className='Header__Links'><Link to={'Portfolio'} activeClass="Active" spy={true} smooth={true} offset={0} duration={500}>portfolio </Link> </li>
                            <li className='Header__Links'><Link to={'Team'} activeClass="Active" spy={true} smooth={true} offset={0} duration={500}>Team </Link> </li>
                            <li className='Header__Links'><Link to={'Blogs'} activeClass="Active" spy={true} smooth={true} offset={0} duration={500}>blog </Link> </li>
                            <li className='Header__Links'><Link to={'Contact'} activeClass="Active" spy={true} smooth={true} offset={0} duration={500}>contact </Link> </li>
                        </ul>
                    </div>
                    <div className='cursor-pointer md:hidden' onClick={() => setOpenTop(!openTop)}>
                        <FaBars className={'text-white text-[26px]'} />
                    </div>
                    <div className={
                        openTop
                            ? "w-full  bg-[#101010] h-[240px]  transition-all duration-500 left-0 top-[99px] fixed overflow-hidden md:hidden"
                            : "w-full h-0 translate-x-[0] bg-[#101010] left-0 top-[99px] transition-all duration-500 fixed overflow-hidden"
                    }>
                        <ul className='flex flex-col py-4 px-8 space-y-4'>
                            <li className='Header__Links'><Link to={'Home'} activeClass="Active" spy={true} smooth={true} offset={0} duration={500}>home </Link> </li>
                            <li className='Header__Links'><Link to={'About'} activeClass="Active" spy={true} smooth={true} offset={0} duration={500}>about </Link> </li>
                            <li className='Header__Links'><Link to={'Services'} activeClass="Active" spy={true} smooth={true} offset={0} duration={500}>services </Link> </li>
                            <li className='Header__Links'><Link to={'Portfolio'} activeClass="Active" spy={true} smooth={true} offset={0} duration={500}>Portfolio </Link> </li>
                            <li className='Header__Links'><Link to={'Team'} activeClass="Active" spy={true} smooth={true} offset={0} duration={500}>Team </Link> </li>
                            <li className='Header__Links'><Link to={'Blogs'} activeClass="Active" spy={true} smooth={true} offset={0} duration={500}>blog </Link> </li>
                            <li className='Header__Links'><Link to={'Contact'} activeClass="Active" spy={true} smooth={true} offset={0} duration={500}>contact </Link> </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}