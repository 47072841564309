import TitleSmall from "../PropsComponents/TitleSmall";
import AbImg from "../../assets/img/about.jpg";
import { AiOutlineCheck } from "react-icons/ai";
export default function HomeSecond() {
    return (
        <>
            <div id="About" className="w-full bg-[#101010] py-[120px] relative after:absolute after:bottom-[-40px] after:left-[50%] after:w-[1px] after:h-[80px] after:bg-mainText before:absolute before:top-[-40px] before:left-[50%] before:w-[1px] before:h-[80px] before:bg-mainText">
                <div className="max-w-screen-lg mx-auto  max-xl:px-6 max-md:flex-col max-md:space-y-12 flex">
                    <div className="sm:w-[80%] mx-auto md:w-3/5">
                        <TitleSmall SmallTitle={'ESTABLISHED 1985'} Title={'ARTEFACT'} TitleBorder={'TATTOO'} />
                        <p className="sm:pr-8 max-sm:text-justify">
                            Tattoo viverra tristique usto duis vitae diam neque nivamus aestan the artine arinian aten mis viverra nec lacus nedana duru edino setlie suscipe curas tristique inila duman aten elit finibus vivera alacus fermen. Lorem arena nuam enim mi obortis esen the uctus cumsan solden malisuametion ametion in the auctor orci done vitae.
                        </p>
                        <ul className="mt-6 max-sm:text-[13px]">
                            <li className="flex space-x-3 items-center">
                                <AiOutlineCheck className="text-mainText" />
                                <span>Comfortable and relaxing environment</span>
                            </li>
                            <li className="flex space-x-3 items-center">
                                <AiOutlineCheck className="text-mainText" />
                                <span>Experienced artist input on your tattoo</span>
                            </li>
                            <li className="flex space-x-3 items-center">
                                <AiOutlineCheck className="text-mainText" />
                                <span>Top of the line cleaning and safety protocols</span>
                            </li>
                            <li className="flex space-x-3 items-center">
                                <AiOutlineCheck className="text-mainText" />
                                <span>Full aftercare instructions for a well-healed tattoo</span>
                            </li>
                        </ul>
                    </div>
                    <div className="sm:w-[80%] mx-auto md:w-2/5">
                        <img src={AbImg} />
                    </div>
                </div>
               
            </div>
        </>
    )
}