import React, { useState } from "react";
import TitleSmall from "../PropsComponents/TitleSmall";
import { AiOutlineMinus } from "react-icons/ai";
import HomeAccardion from "./HomeAccardion";
export default function HomeHow() {
    const faqs = [
        {
            id: 1,
            header: "FREE CONSULTATION",
            text: "Email us or come into the shop to discuss your tattoo. Bring your ideas, and we'll guide you on how to make it a tattoo you'll love.",
            num: 1
        },
        {
            id: 2,
            header: "BOOK AN APPOINTMENT",
            text: "Once you’ve decided what you want, we will give you a quote so you can book the date for your tattoo and pay your deposit.",
            num: 2
        },
        {
            id: 3,
            header: "GET YOUR TATTOO",
            text: "When you arrive at the shop, your artist will have your design prepared. Now you settle in and enjoy getting your new tattoo.",
            num: 3
        }
    ];
    const [active, setActive] = useState(null);

    const handleToggle = (index) => {
        if (active === index) {
            setActive(null);
        } else {
            setActive(index);
        }
    }
    return (
        <>
            <div className="w-full bg-[#101010] py-[60px]">
                <div className="max-w-screen-lg mx-auto  max-xl:px-6 max-md:flex-col max-md:space-y-12 flex mt-24">
                    <div className="md:w-1/2">
                        <TitleSmall SmallTitle={'OUR PROCESS'} Title={'HOW IT'} TitleBorder={'WORKS'} />
                        <p className="sm:pr-8 max-sm:text-justify">
                            Tattoo viverra tristique usto duis vitae diam neque nivamus aestan the artine arinian aten mis viverra nec lacus nedana duru edino setlie suscipe curas tristique inila duman aten elit finibus vivera alacus fermen. Lorem arena nuam enim mi obortis esen the uctus cumsan solden malisuametion ametion in the auctor orci done vitae.
                        </p>
                        <ul className="mt-6 max-sm:text-[13px]">
                            <li className="flex space-x-3 items-center">
                                <AiOutlineMinus className="text-mainText" />
                                <span>We're professional and certified tattoo</span>
                            </li>
                            <li className="flex space-x-3 items-center">
                                <AiOutlineMinus className="text-mainText" />
                                <span>We care about our customers satisfaction</span>
                            </li>

                        </ul>
                    </div>
                    <div className="md:w-1/2">
                        
                                    <div className="card mt-4">
                                        <div className="card-body">
                                            {faqs.map((faq, index) => {
                                                return (
                                                    <HomeAccardion key={index} active={active} handleToggle={handleToggle} faq={faq} />
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}