import React, { useState, useRef } from "react";
import {AiOutlinePlus , AiOutlineMinus} from 'react-icons/ai';
export default function HomeAccardion(props) {
  
    const contentEl = useRef();
    const { handleToggle, active, faq } = props;
    const { header, id, text ,num } = faq;
    return (
        <>

            <div className="rc-accordion-card border-b-[1px] border-white/10">
                <div className="rc-accordion-header">
                    <div className={`rc-accordion-toggle py-3 ${active === id ? 'active' : ''}`} onClick={() => handleToggle(id)}>
                        <h5 className="rc-accordion-title text-[18px] font-Oswald text-white"><span className="text-mainText">{num} .</span> {header}</h5>
                       {active ? <AiOutlineMinus className="text-white opacity-20" /> : <AiOutlinePlus className="text-white opacity-20" />}
                       
                    </div>
                </div>
                <div ref={contentEl} className={`rc-collapse ${active === id ? 'show' : ''}`} style={
                    active === id
                        ? { height: contentEl.current.scrollHeight }
                        : { height: "0px" }
                }>
                    <div className="rc-accordion-body">
                        <p className='mb-0'>{text}</p>
                    </div>
                </div>
            </div>

        </>
    )
}