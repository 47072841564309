import { Link } from "react-scroll";

export default function ServiceSmall(props) {
    return (
        <>
            <div className="w-full flex max-md:flex-col-reverse  space-y-12 mt-12">
              
                <div className="md:w-1/2 max-md:mt-12 flex items-center">
                    <div className="flex max-sm:flex-col max-sm:space-y-6  sm:px-4 lg:px-12 sm:space-x-4">
                        <div className="Text-Strock-Title  text-[70px] font-Oswald leading-none">
                            {props.ID}
                        </div>
                        <div>
                            <p className="uppercase text-white text-[27px] font-normal tracking-[3px] font-Oswald">{props.title}</p>
                            <p className="mt-4">{props.desc}</p>
                            <div className="mt-4 flex">
                                <Link className="relative z-0 bg-mainText cursor-pointer overflow-hidden after:transition-all after:duration-300 hover:text-black after:z-0 px-[24px] py-[12px] text-white text-[15px] font-medium after:absolute after:w-[110%] after:h-[130%] after:bg-white after:top-0 after:left-[-5%] after:rotate-[-5deg] after:translate-y-[-120%] hover:after:translate-y-[-12%]"><span className="relative z-10">Read More</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="md:w-1/2 overflow-hidden">
                    <img src={props.image} className="transition-all duration-1000 ease-linear cursor-pointer hover:scale-110" />
                </div>
            </div>
        </>
    )
}