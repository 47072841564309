import TitleSmall from "../PropsComponents/TitleSmall";
import { FiPhoneCall } from "react-icons/fi";
import { Select, Option } from "@material-tailwind/react";
import "../../assets/js/script";
export default function HomeContact() {
    return (
        <>
            <div id="Contact" className="w-full relative after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-black/50 bg-contact-bg bg-center bg-cover bg-fixed py-36">
                <div className="max-w-screen-lg relative z-20 mx-auto  max-xl:px-6 max-md:flex-col max-md:space-y-12 flex">
                    <div className="md:w-1/2">
                        <TitleSmall SmallTitle={'BOOK YOUR TATTOO'} Title={'APPOINTMENT'} />
                        <p className="font-Barlow text-white text-[15px] font-normal pr-12">
                            Tattoo viverra tristique usto duis vitae diam neque nivamus aestan atene artine arinianu ateli finibus viverra nec lacus. nedana duru edino setlie fermen.
                        </p>
                        <div className="flex space-x-6 mt-12">
                            <div className="flex items-center">
                                <FiPhoneCall className="text-[40px] text-white" />
                            </div>
                            <div className="For_Small">
                                <TitleSmall SmallTitle={'APPOINTMENT'} Title={'855 100 4444'} />
                            </div>
                        </div>
                    </div>
                    <div className="md:w-1/2 bg-[#101010] px-6 py-16">
                        <form>
                            <div className="flex space-x-4">
                                <div className="w-1/2">
                                    <input type="text" placeholder="Name" className="w-full focus:outline-0 border-[1px] p-[15px] text-[#999] border-white/10 bg-transparent" />
                                </div>
                                <div className="w-1/2">
                                    <input type="number" placeholder="Phone" className="w-full focus:outline-0 border-[1px] p-[15px] text-[#999] border-white/10 bg-transparent" />
                                </div>
                            </div>
                            <div className="flex space-x-4 mt-4">
                                <div className="w-1/2">
                                    <input type="date" placeholder="Date" className="w-full focus:outline-0 border-[1px] p-[15px] text-[#999] border-white/10 bg-transparent" />
                                </div>
                                <div className="w-1/2">
                                    <input type="time" placeholder="Time" className="w-full focus:outline-0 border-[1px] p-[15px] text-[#999] border-white/10 bg-transparent" />
                                </div>
                            </div>
                            <div className="flex space-x-4 mt-4">
                                <div className="w-1/2 For__Select">

                                    <select class="ddl-select bg-transparent" id="list" name="list">
                                        <option>Services</option>
                                        <option value="1">Tattooing</option>
                                        <option value="2">Piercing</option>
                                        <option value="2">Laser Removal</option>
                                    </select>

                                </div>
                                <div className="w-1/2">
                                <select class="ddl-select1 bg-transparent" id="list1" name="list">
                                        <option>Choose Artist</option>
                                        <option value="1">Andreas</option>
                                        <option value="2">Daniel</option>
                                        <option value="2">Jason</option>
                                    </select>
                                </div>
                            </div>
                            <div className="flex space-x-4 mt-6">
                                <button className="font-normal w-full h-[57px] bg-mainText transition-all duration-300 font-Barlow text-white text-[15px] hover:bg-white hover:text-black">Book Your Tattoo</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}