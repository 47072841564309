import React from "react";
import TitleSmall from "../PropsComponents/TitleSmall";
import Slider from "react-slick";
import I1 from "../../assets/img/9.jpg";
import I2 from "../../assets/img/7.jpg";
import I3 from "../../assets/img/8.jpg";
import TeamSlider from "../PropsComponents/TeamSlider";
export default function HomeTeam() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:false
    };
    const sliderData = [
        {image: I1, name:'Jason White' , proffesion:"Tattoo Artist" , description:"Tattoo aliquet miss orci elit gene on tristique in the dream vitaen aliuam lorem tincidunt felis sed gravida aliquam the neque miss blue hendren mavition duru sapien mana amenta the mollis."},
        {image: I2, name:'Andreas Martin' , proffesion:"Piercing Artist" , description:"Tattoo aliquet miss orci elit gene on tristique in the dream vitaen aliuam lorem tincidunt felis sed gravida aliquam the neque miss blue hendren mavition duru sapien mana amenta the mollis."},
        {image: I3, name:'Daniel Brown' , proffesion:"Tattoo Artist" , description:"Tattoo aliquet miss orci elit gene on tristique in the dream vitaen aliuam lorem tincidunt felis sed gravida aliquam the neque miss blue hendren mavition duru sapien mana amenta the mollis."},
    ]
    return (
        <>

            <div id="Team" className="w-full bg-[#101010] pt-24 pb-48 TeamSlider">
                <div className="max-w-screen-lg mx-auto max-xl:px-6">
                    <div className={'text-center'}>
                        <TitleSmall SmallTitle={'OUR TEAM'} Title={'TATTOO'} TitleBorder={'ARTISTS'} />

                    </div>
                    <div className="mt-24">
                        <Slider {...settings}>
                            {
                                sliderData && sliderData.map((item,index) => {
                                   return <TeamSlider image={item.image} name={item.name} proffesion={item.proffesion} description={item.description} />
                                })
                            }
                        </Slider>
                    </div>
                </div>
            </div>

        </>
    )
}